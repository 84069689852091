h3 {
  color: rgb(89, 0, 255);
  font-weight: 600;
}

.About-paragraph {
  line-height: 170%;
}

.Resume-link {
  text-decoration: none;
  
}

.Resume-link:hover {
  color:rgb(134, 70, 255);
}

.Resume-heading {
  color:rgb(134, 70, 255);

}