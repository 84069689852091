.Navigation-link.nav-link {
    color: black;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: normal;
    text-align: center;
}

.Navigation-link.nav-link:hover {
    color: rgb(89, 0, 255);
    
   font-weight: 500;
    text-decoration: none;
}

.Navigation-link.nav-link.active:hover {
    color: white;
    font-weight: 500;
    text-decoration: none;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: rgb(101, 0, 148) !important;
}